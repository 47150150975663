import { AsyncState, Nullable, Uid, AsyncStateError } from 'state-domains/types';

import { APILink } from './shim/types';

export interface TokenState extends AsyncState {
    token: Nullable<string>;
}

export interface NotificationsState {
    trayState: TrayState;
    messageState: MessageState;
    resourceState: ResourceState;
    filterState: FilterState;
    instanceUuid: string;
    instanceDisplayName: string;
    notificationsAuth: TokenState;
    digestState: DigestState;
}

interface NotificationsPagedState<T = any> extends AsyncState {
    total: number;
    itemIds: string[];
    map: Record<string, T>;
    next?: string | null;
    prev?: string | null;
}

export interface DigestState extends AsyncState {
    digestPreference: Nullable<DigestPreference>;
}

export type MessageState = NotificationsPagedState<AsyncMessage>;

export interface TrayState extends AsyncState {
    total: number;
    itemIds: string[];
    seen?: string;
}

export interface FilterState extends AsyncState {
    map: FilterMap;
}

export interface ResourceState extends AsyncState {
    map: ResourceMap;
}

export type MessageMap = Record<string, AsyncMessage>;

export interface Message {
    uuid: string;
    read: boolean;
    topic: ProjectTopics;
    message: string;
    createdAt: string;
    self: string;
    link: APILink;
    centralInstanceName?: string;
    centralInstanceUuid?: string;
}

export type AsyncMessage = Message & AsyncState;

export interface MessagesPayload {
    total: number;
    messages: Message[];
    next?: string;
    prev?: string;
    seen?: string;
}

export interface PollMessagesPayload extends MessagesPayload {
    limit: number;
}

export interface Filter {
    id: number;
    resource: string;
    topic: ProjectTopics;
    channel: Channel;
    enabled: boolean;
    self: string;
}
export type AsyncFilter = Filter & AsyncState;
export type FilterMap = Record<string, AsyncFilter>;
export interface FiltersPayload {
    filters: Filter[];
}
export interface FilterQueryProps {
    resource: string;
    topic: ProjectTopics;
    channel: Channel;
}

export interface Resource {
    id: number;
    resource: string;
    self: string;
}
export type AsyncResource = Resource & AsyncState;
export type ResourceMap = Record<string, AsyncResource>;
export interface ResourcesPayload {
    unsubscribers: Resource[];
}

export type NISOrdering = 'inserted_at' | '-inserted_at' | 'last_modified' | '-last_modified';

export interface NSQueryProps {
    offset?: number;
    limit?: number;
    read?: 'true' | 'false';
    topic?: ProjectTopics;
    resource?: string;
    ordering?: NISOrdering;
}

export interface PollQueryProps {
    limit?: number;
    cursor?: string;
}

export interface TokenPayload {
    error?: Error;
    token: Nullable<string>;
}

export interface UidKeysPayload {
    uids: string[];
}

export interface UpdateMessagesPayload extends UidKeysPayload {
    read: boolean;
}

// https://github.com/seequent/central/blob/master/central/notifications/messages.py#L46
export enum ProjectTopics {
    PROJECT = 'central:project',
    REVISION = 'central:project.revision',
    BRANCH_RENAME = 'central:project.branch.rename',
    GRANT_ACCESS = 'central:project.grant_access',
    NOTES_NEW = 'central:project.note.new',
    NOTES_REPLY = 'central:project.note.reply',
    MENTION = 'central:mention',
    SHARE_SCENE = 'central:project.scene.share_project_member',
}

export enum ResourceType {
    PROJECT = 'PROJECT',
    INSTANCE = 'INSTANCE',
    ALL_PROJECT = 'ALL_PROJECT',
}

export const topicsDisabledByDefaultForEmail: ProjectTopics[] = [
    ProjectTopics.NOTES_NEW,
    ProjectTopics.NOTES_REPLY,
];
export const topicsDisabledByDefaultForApp: ProjectTopics[] = [];

export enum Channel {
    APP = 'inbox',
    EMAIL = 'email',
}

export interface FilterProps {
    projectID?: Uid;
    topic: ProjectTopics;
    resourceType: ResourceType;
}

export enum DigestPeriod {
    INSTANTLY = 0, // 0 Minutes
    MINS30 = 30, // 30 Minutes
}

export interface DigestPreference {
    id: number;
    audience: string;
    resource: string;
    selfLink: string;
    period: DigestPeriod;
}

interface NotificationsFailurePayloadBase {
    error: AsyncStateError;
    notificationsIdList: string[];
}

export type DeleteNotificationsFailurePayload = NotificationsFailurePayloadBase;

export type UpdateNotificationsFailurePayload = NotificationsFailurePayloadBase;
